<template>
	<Modal
		width="800px"
	>
		<template #modal-title>
			{{ linkName }} Link
			<v-spacer />
			<Dropdown
				v-model="selectedLanguage"
				label="Language"
				:items="assignedLanguageItems"
				@change="updateEmbedCodeWithLanguage"
			/>
		</template>
		<template #modal-content>
			<Prism>
				{{ embedCode }}
			</Prism>
		</template>
		<template #modal-footer>
			<v-spacer />
			<SecondaryActionButton @click="copyToClipboard(true)">
				Copy with tags
			</SecondaryActionButton>
			<SecondaryActionButton @click="copyToClipboard(false)">
				Copy with URL only
			</SecondaryActionButton>
			<SecondaryActionButton @click="$emit('close')">
				Cancel
			</SecondaryActionButton>
		</template>
	</Modal>
</template>

<script>
import Prism from 'vue-prism-component'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import Modal from '../../../../../shared/components/modal.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'

export default {
	components: { Modal, SecondaryActionButton, Prism, Dropdown },
	props: {
		linkName: String,
		embedLink: String,
		assignedLanguageItems: Array
	},
	data: () => ({
		embedCode: '',
		selectedLanguage: 0,
		selectedLanguageCode: 'default'
	}),
	created () {
		this.embedCode = this.embedLink
	},
	methods: {
		copyToClipboard (includeTags) {
			if (includeTags) {
				navigator.clipboard.writeText(this.embedCode)
			} else {
				const parser = new DOMParser()
				const link = parser.parseFromString(this.embedCode, 'text/html')
				const href = link.body.getElementsByTagName('a')[0].href
				navigator.clipboard.writeText(href)
					.catch(() => {
						showSnackbar({
							text: 'Error copying to clipboard',
							color: 'red'
						})
					})
			}
		},
		updateEmbedCodeWithLanguage (value) {
			const languageCode = this.assignedLanguageItems.find(({ value: languageID }) => languageID === value).languageCode
			this.embedCode = this.embedCode.replace(`languagecode=${this.selectedLanguageCode}`, `languagecode=${languageCode}`)
			this.selectedLanguageCode = languageCode
		}
	}
}
</script>
