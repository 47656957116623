<template>
	<Modal width="500px">
		<template #modal-title>
			Delete {{ linkName }}
		</template>
		<template #modal-content>
			Are you sure you wish to delete this Link? <br>
			You will not be able to recover it if you confirm its deletion.
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="close">
				Close
			</SecondaryActionButton>
			<DeleteButton @click="deleteLink">
				Delete
			</DeleteButton>
		</template>
	</Modal>
</template>
<script>
import Modal from '../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import DeleteButton from '../../../../../shared/components/delete-button.vue'
import { deleteLink } from '../../../../../shared/utils/api/admin-portal/links/links.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
export default {
	components: { DeleteButton, SecondaryActionButton, Modal },
	props: {
		linkId: Number,
		linkTypeId: Number,
		linkName: String
	},
	setup () {
		return {
			showSnackbar
		}
	},
	methods: {
		close () {
			this.$emit('close')
		},
		async deleteLink () {
			const link = {
				linkId: this.linkId,
				linkTypeId: this.linkTypeId
			}
			await deleteLink(link)
			showSnackbar('This link has been deleted')
			this.$emit('deleted')
			this.close()
		}
	}
}
</script>
