<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="actionLinkSearchQuery"
			search-query-label="Action Link Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changeActionLinkSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="linkAuthType"
					:items="authTypeFilter"
					custom-sort
					label="Authentication Type"
					@input="changeLinkAuthType($event)"
				/>
				<Dropdown
					v-model="linkEnabledStatus"
					:items="statusFilterItems"
					custom-sort
					label="Status"
					@input="changeLinkEnabledStatus($event)"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="$emit('create-link', ACTION_LINK_TYPE)"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Action Links
			</template>
			<template #body>
				<DataTable
					:items="filteredActionLinks"
					:headers="tableHeaders"
					sort-by="linkId"
					sort-desc
					@click:row="rowClick"
				>
					<template #item.isEnabled="{ item }">
						{{ item.isEnabled ? 'Enabled' : 'Disabled' }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userFullPermissions && ((item.brandId === 0 && userHasUniversalBrand) || item.brandId !== 0)"
							tooltip-text="Edit Action Link"
							@click="$emit('edit-link', item, ACTION_LINK_TYPE)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else-if="userReadOnly || (item.brandId === 0 && !userHasUniversalBrand)"
							tooltip-text="View Action Link"
							@click="$emit('edit-link', item, ACTION_LINK_TYPE)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							tooltip-text="View Embed Code"
							@click.stop.prevent="$emit('view-link', item)"
						>
							mdi-link
						</IconButton>
						<IconButton
							v-if="shouldShowPreview(item)"
							tooltip-text="Preview link"
							@click.stop.prevent="$emit('preview-link', item)"
						>
							mdi-open-in-app
						</IconButton>
						<IconButton
							v-if="userFullPermissions && ((item.brandId === 0 && userHasUniversalBrand) || item.brandId !== 0)"
							tooltip-text="Clone Action Link"
							@click.stop.prevent="$emit('clone-link', item, ACTION_LINK_TYPE)"
						>
							mdi-content-duplicate
						</IconButton>
						<IconButton
							v-if="userFullPermissions && ((item.brandId === 0 && userHasUniversalBrand) || item.brandId !== 0)"
							tooltip-text="Delete Action Link"
							@click.stop.prevent="$emit('remove-link', item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { changeActionLinkSearchQuery, actionLinkSearchQuery, changeLinkAuthType, linkAuthType, changeLinkEnabledStatus, linkEnabledStatus } from '../../../../../../shared/state/links.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../shared/state/brands.js'
import { ACTION_LINK_TYPE } from '../link-types.js'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'

export default {
	components: { SectionCard, DataTable, FilterBar, PrimaryActionButton, Dropdown, IconButton },
	props: {
		actionLinks: {
			type: Array,
			default: () => []
		},
		linkAuthTypes: {
			type: Array,
			default: () => []
		},
		statusFilterItems: {
			type: Array,
			default: () => []
		},
		userFullPermissions: Boolean,
		userReadOnly: Boolean
	},
	setup () {
		return {
			changeActionLinkSearchQuery,
			actionLinkSearchQuery,
			changeLinkAuthType,
			linkAuthType,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeLinkEnabledStatus,
			linkEnabledStatus
		}
	},
	data () {
		return {
			ACTION_LINK_TYPE
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		authTypeFilter () {
			const authTypes = this.linkAuthTypes
			return [
				{ value: null, text: 'View All' }, ...authTypes.sort((a, b) => a.text.localeCompare(b.text))
			]
		},
		filteredActionLinks () {
			return this.actionLinks.filter(({ linkName, brandId, authenticationDetails, isEnabled }) => {
				let check = true
				if (actionLinkSearchQuery.value) check = linkName.toLowerCase().includes(actionLinkSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				if (linkAuthType.value !== null) check = check && authenticationDetails.authTypeId === linkAuthType.value
				if (linkEnabledStatus.value !== null) check = check && isEnabled === linkEnabledStatus.value
				return check
			})
		},
		tableHeaders () {
			return [
				{ text: 'ID', value: 'linkId', width: '10%' },
				{ text: useLabels('Brand'), value: 'brandName', width: '15%' },
				{ text: 'Link Name', value: 'linkName', width: '20%' },
				{ text: 'Authentication Type', value: 'authenticationType', width: '20%' },
				{ text: 'Status', value: 'isEnabled', width: '10%' },
				{ text: 'Created Date', value: 'formattedCreatedDate', width: '10%' },
				{ text: 'Action', value: 'action', width: '15%', sortable: false }
			]
		}
	},
	methods: {
		rowClick (row) {
			this.$emit('edit-link', row, ACTION_LINK_TYPE)
		},
		clearFilters () {
			changeLinkAuthType(null)
			changeLinkEnabledStatus(null)
		},
		shouldShowPreview (link) {
			return !(link.linkFields?.find(
				field => !field.previewValue || field.previewValue === ''
			) ?? null)
		}
	}
}
</script>
